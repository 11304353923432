<template>
  <div class="form-two">
    <div class="form-two-title">
      {{ detail.name }}
    </div>
    <div class="form-two-form">
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>称呼</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.name" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>联系方式</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.phone" placeholder=" " />
        </div>
      </div>
      <div class="form-item mes">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>内容</span>
        </div>
        <div class="form-item-right">
          <el-input
            type="textarea"
            resize="none"
            :autosize="{ minRows: 10 }"
            v-model.trim="form.mes"
            placeholder=" "
          />
        </div>
      </div>
      <div class="form-item">
        <el-button
          type="primary"
          class="submit"
          @click="submit"
          :loading="submitLoading"
          >点击提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getModuleBaseMes, addInformation } from "@/api/base.js";

export default {
  name: "FormTwo",
  data() {
    return {
      submitLoading: false, // 提交loading
      detail: {},
      searchObj: {
        moduleCode: "",
        typeId: 0,
        cId: 0,
      },
      form: {
        name: "", // 称呼
        phone: "", // 联系方式
        mes: "", // 内容
      },
    };
  },
  computed: {},
  created() {
    const { query } = this.$route;
    this.searchObj.moduleCode = query.module_code;
    this.searchObj.cId = query.cid;
    this.getModuleBaseMes();
  },
  methods: {
    // 提交
    submit() {
      const { name, phone, mes } = this.form;
      if (!name || !phone || !mes) {
        this.$message({
          type: "warning",
          message: "请填写信息内容",
        });
        return;
      }
      let data = this.form;
      data["title"] = this.detail.name;
      data["type"] = 2;

      // console.log(data);

      this.submitLoading = true;
      addInformation(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("提交成功！");
            this.form = {
              name: "", // 称呼
              phone: "", // 联系方式
              mes: "", // 内容
            };
          } else {
            this.$message.warning(res.errMessage);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    getModuleBaseMes() {
      this.loadingDetail = true;
      let params = {
        module_code: this.searchObj.moduleCode,
        id: this.searchObj.cId,
      };
      getModuleBaseMes(params)
        .then((res) => {
          const { data } = res;
          this.detail = data;
          this.$emit("clickItem", data.name);
        })
        .finally(() => {
          this.loadingDetail = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .form-two {
    max-height: calc(100% - 46px) !important;
    .form-two-title {
      font-size: 22px;
    }
    .form-item-left {
      font-size: 14px;
      width: 30%;
    }
    .form-item-right {
      width: 70%;
      display: flex;

      .el-input {
        width: 40%;
        margin-right: 4%;
      }
      .el-select {
        width: 40%;
        margin-right: 4%;
      }
      .el-button {
      }
    }
    .form-item {
      .el-button.submit {
        width: 50%;
        margin: 16px auto;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .form-two {
    max-height: 100% !important;
    .form-two-title {
      font-size: 20px;
    }
    .form-item-left {
      font-size: 12px;
      width: 36%;
    }
    .form-item-right {
      width: 64%;
      .el-input {
      }
      .el-select {
        width: 50%;
      }
      .el-button {
      }
      &.code {
        .el-input {
          width: 50%;
          margin-right: 4%;
        }
        .el-button {
          width: 46%;
        }
      }
    }
    .form-item {
      .el-button.submit {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}
.form-two {
  overflow-y: auto;
  .form-two-title {
    font-size: 14px;
    line-height: 2;
    text-indent: 2em;
    padding: 16px 0 22px;
    border-bottom: 1px solid #5da8f3;
  }
  .form-two-form {
    margin-top: 22px;
    .form-item {
      display: flex;
      align-items: center;
      margin-top: 2%;

      .el-buttom.submit {
      }
    }

    .form-item-left {
      .icon-diamond {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #5da8f3;
        transform: rotate(45deg);
      }
      span {
        margin-left: 4%;
      }
    }
    .form-item-right {
      .el-input {
      }
      .el-select {
      }
      .el-button {
      }
    }
  }
}
</style>